import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import dynamic from 'next/dynamic';
import { makeStyles } from "@material-ui/core/styles";
import Loading from 'components/Functions/Loading';

const GridContainer = dynamic(() => import('/components/Grid/GridContainer.js'), { ssr: true });
const GridItem = dynamic(() => import('/components/Grid/GridItem.js'), { ssr: true });
const Map = dynamic(() => import('/components/Maps/Map.js'), { ssr: false, loading: () => <Loading /> });
const InfoArea = dynamic(() => import('/components/InfoArea/InfoArea.js'), { ssr: true });

// import GridContainer from "/components/Grid/GridContainer.js";
// import GridItem from "/components/Grid/GridItem.js";
// import Map from "/components/Maps/Map.js";
// import InfoArea from "/components/InfoArea/InfoArea.js";

import { LocationCity,  Cloud, Security } from '@material-ui/icons';

import styles from "/styles/jss/bbhost/pages/landingPageSections/teamStyle.js";
const useStyles = makeStyles(styles);

import { trans } from "lang/lang.js";

export default function RedeSection(props) {
  const classes = useStyles();
  
  return (
    <div className={classes.section}>
        <h2 className={classNames(classes.title)}>{trans("index::rede_section.title")}</h2>
        <p className={classNames(classes.title)}>{trans("index::rede_section.subTitle")}</p>
        <GridContainer>            
            <GridItem xs={12} sm={12} md={12}>
                <Map mapData={props.map} height="500px" />
            </GridItem>          
        </GridContainer>        
        <GridContainer justifyContent="center">        
            <GridItem xs={12} sm={10} md={4}>
                <InfoArea iconColor="success" icon={LocationCity} title={trans("index::rede_section.help.datacenter.title")} description={trans("index::rede_section.help.datacenter.description")}/>
            </GridItem>
            <GridItem xs={12} sm={10} md={4}>
                <InfoArea iconColor="info" icon={Cloud} title={trans("index::rede_section.help.pop.title")} description={trans("index::rede_section.help.pop.description")}/>
            </GridItem> 
            <GridItem xs={12} sm={10} md={4}>
                <InfoArea iconColor="danger" icon={Security} title={trans("index::rede_section.help.msr.title")} description={trans("index::rede_section.help.msr.description")}/>
            </GridItem>         
        </GridContainer>
    </div>
  );
}
